.button {
    background-color: #1e5fa7;
    border-color: #1e5fa7;
    transition: .25s ease-in-out;
    line-height: 28px;
    font-size: 40px;
    border-radius: 8px;
    cursor: pointer;
    padding: 24px 48px;
    border: 1px solid;
    color: #fff;
    font-size: 34px;
    line-height: 28px;
    text-decoration: none;
    text-align: center;
}

.button:hover {
    background-color: #2e417f;
    border-color: #2e417f;
}